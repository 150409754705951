import "./Social.css";

export const Social = () => {
  return (
    <>
      <div className="button-container">
        <a
          href="https://github.com/Jackthomsonn"
          target="_blank"
          rel="noreferrer"
        >
          <button className="github">
            <svg
              width="20"
              height="20"
              viewBox="0 0 1024 1024"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 0C3.58 0 0 3.58 0 8C0 11.54 2.29 14.53 5.47 15.59C5.87 15.66 6.02 15.42 6.02 15.21C6.02 15.02 6.01 14.39 6.01 13.72C4 14.09 3.48 13.23 3.32 12.78C3.23 12.55 2.84 11.84 2.5 11.65C2.22 11.5 1.82 11.13 2.49 11.12C3.12 11.11 3.57 11.7 3.72 11.94C4.44 13.15 5.59 12.81 6.05 12.6C6.12 12.08 6.33 11.73 6.56 11.53C4.78 11.33 2.92 10.64 2.92 7.58C2.92 6.71 3.23 5.99 3.74 5.43C3.66 5.23 3.38 4.41 3.82 3.31C3.82 3.31 4.49 3.1 6.02 4.13C6.66 3.95 7.34 3.86 8.02 3.86C8.7 3.86 9.38 3.95 10.02 4.13C11.55 3.09 12.22 3.31 12.22 3.31C12.66 4.41 12.38 5.23 12.3 5.43C12.81 5.99 13.12 6.7 13.12 7.58C13.12 10.65 11.25 11.33 9.47 11.53C9.76 11.78 10.01 12.26 10.01 13.01C10.01 14.08 10 14.94 10 15.21C10 15.42 10.15 15.67 10.55 15.59C13.71 14.53 16 11.53 16 8C16 3.58 12.42 0 8 0Z"
                transform="scale(64)"
                fill="#f2f0f6"
              />
            </svg>
            Github
          </button>
        </a>
        <a
          href="https://www.linkedin.com/in/jackthomsonn"
          target="_blank"
          rel="noreferrer"
        >
          <button className="linkedin">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 67 66"
              fill="#fff"
              fillRule="evenodd"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              width="20"
              height="20"
            >
              <use xlinkHref="#A" x="1" y="1" />
              <symbol id="A" overflow="visible">
                <g stroke="none" fillRule="nonzero">
                  <path
                    d="M59.26 0H4.724C2.12 0 0 2.066 0 4.61v54.788c0 2.53 2.12 4.6 4.724 4.6h54.54c2.61 0 4.736-2.07 4.736-4.6V4.61C64 2.066 61.874 0 59.26 0z"
                    fill="#0177b5"
                  />
                  <path d="M9.49 23.992H19v30.54H9.49zm4.748-15.2c3.034 0 5.5 2.466 5.5 5.5a5.51 5.51 0 0 1-5.498 5.506 5.52 5.52 0 0 1-5.508-5.506 5.5 5.5 0 0 1 5.506-5.5m10.7 15.2h9.104v4.174h.126c1.268-2.4 4.364-4.932 9-4.932 9.612 0 11.386 6.326 11.386 14.548v16.752h-9.486V39.678c0-3.54-.064-8.1-4.932-8.1-4.94 0-5.7 3.86-5.7 7.84v15.108h-9.484v-30.54z" />
                </g>
              </symbol>
            </svg>
            Linkedin
          </button>
        </a>
        <a
          href="https://passionfruitsoftware.com"
          target="_blank"
          rel="noreferrer"
        >
          <button className="passionfruit">
            <svg width="20" height="20" viewBox="0 0 225 225" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="112.5" cy="112.5" r="112.5" fill="#10172A" />
              <path d="M118.27 98.6328C118.27 102.422 117.703 105.781 116.57 108.711C115.438 111.641 113.875 114.199 111.883 116.387C109.93 118.574 107.625 120.43 104.969 121.953C102.312 123.477 99.4609 124.727 96.4141 125.703C93.4062 126.641 90.2812 127.344 87.0391 127.812C83.7969 128.242 80.6133 128.477 77.4883 128.516V155.82H50.1836C50.1836 146.875 50.2031 137.969 50.2422 129.102C50.2812 120.234 50.3398 111.289 50.418 102.266C50.4961 97.5 50.5156 92.7344 50.4766 87.9688C50.4375 83.2031 50.5352 78.3984 50.7695 73.5547C55.7695 72.0703 60.7695 70.957 65.7695 70.2148C70.7695 69.4727 75.9258 69.1016 81.2383 69.1016C84.2461 69.1016 87.2539 69.3555 90.2617 69.8633C93.2695 70.332 96.1406 71.0938 98.875 72.1484C101.648 73.2031 104.207 74.5312 106.551 76.1328C108.934 77.6953 110.984 79.5703 112.703 81.7578C114.422 83.9453 115.77 86.4453 116.746 89.2578C117.762 92.0312 118.27 95.1562 118.27 98.6328ZM92.2539 100.625C92.2539 98.0859 91.4727 96.1133 89.9102 94.707C88.3867 93.2617 86.3945 92.5391 83.9336 92.5391C83.1133 92.5391 82.2539 92.6172 81.3555 92.7734C80.4961 92.8906 79.6758 93.0469 78.8945 93.2422L78.1914 110.352C78.7383 110.43 79.2656 110.469 79.7734 110.469C80.2812 110.469 80.8086 110.469 81.3555 110.469C82.7617 110.469 84.1094 110.234 85.3984 109.766C86.7266 109.297 87.8984 108.633 88.9141 107.773C89.9297 106.875 90.7305 105.84 91.3164 104.668C91.9414 103.457 92.2539 102.109 92.2539 100.625ZM176.98 70.5078C176.746 74.1797 176.531 77.8125 176.336 81.4062C176.141 85 175.887 88.6328 175.574 92.3047L150.73 93.5938V100.977H172.293L171.121 119.141L150.613 119.727L150.73 154.414L121.082 155L122.957 70.5078H176.98Z" fill="#8B5CF6" />
            </svg>
            PassionFruit Software
          </button>
        </a>
        <a
          href="https://glyphgenie.app"
          target="_blank"
          rel="noreferrer"
        >
          <button className="glyphgenie">
            <img src="/glyph-genie.png" alt="Glyph Genie" width={20} height={20} />
            GlyphGenie
          </button>
        </a>
      </div>
    </>
  );
};
